import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './animate.css';
import './styles.css';

const MatchHistory = () => {
  const { username, tag } = useParams();
  const [matches, setMatches] = useState([]);
  const [playerCard, setPlayerCard] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const fetchMatches = async (username, tag) => {
    setLoading(true); // Set loading to true when fetching matches
    try {
        const response = await fetch(`https://orange-sun-bf4d.sarpkulaksizog.workers.dev/api/matches/eu/${username}/${tag}`, {
            headers: {
              'X-App-Secret': 'oycvnlefzvjolqywghkymhgykveylmcn',
              'Content-Type': 'application/json'
            }
          });
      const data = await response.json();
      if (data.data) {
        setMatches(data.data);
        const player = data.data[0].players.all_players.find(player => player.name === username && player.tag === tag);
        if (player && player.assets && player.assets.card && player.assets.card.small) {
          setPlayerCard(player.assets.card.small);
        }
      } else {
        setError('User not found or no matches available');
      }
    } catch (error) {
      setError('Error fetching the matches');
    } finally {
      setLoading(false); // Set loading to false when fetching is complete
    }
  };

  useEffect(() => {
    fetchMatches(username, tag);
  }, [username, tag]); // Re-fetch data when username or tag changes

  const renderPlayers = (teamPlayers) => {
    const partyGroups = teamPlayers.reduce((groups, player) => {
      if (!groups[player.party_id]) {
        groups[player.party_id] = [];
      }
      groups[player.party_id].push(player);
      return groups;
    }, {});

    return Object.values(partyGroups).map((party, index) => (
      <div key={index} className={`party ${party.length > 1 ? 'premade' : ''} p-4 mb-4 bg-gray-900 rounded-lg`}>
        {party.map((player) => (
          <div
            key={player.puuid}
            className={`player flex items-center mb-2 ${player.name === username && player.tag === tag ? 'bg-teal-700' : ''}`}
          >
            {player.assets && player.assets.agent && (
              <img src={player.assets.agent.small} alt={player.character} className="w-10 h-10 rounded-full mr-2 border-2 border-teal-500" />
            )}
            <div className="player-details flex-grow">
              <p className={`text-sm font-medium ${player.name === username && player.tag === tag ? 'text-yellow-300' : 'text-white'}`}>
                {player.name} ({player.tag}) - {player.character}
              </p>
              <div className="player-stats text-xs text-gray-400 mt-1">
                <p>KDA: {player.stats.kills}/{player.stats.deaths}/{player.stats.assists}</p>
                <p>ACS: {player.stats.score}</p>
              </div>
              <button onClick={() => {
                setLoading(true);
                navigate(`/${player.name}/${player.tag}`);
              }} className="go-to-profile-button">Go to Profile</button>
            </div>
          </div>
        ))}
      </div>
    ));
  };

  const isWin = (playerTeam, match) => {
    const winningTeam = match.teams.blue.has_won ? 'Blue' : 'Red';
    return playerTeam === winningTeam;
  };

  const getMatchResult = (playerTeam, match) => {
    return isWin(playerTeam, match) ? 'Victory' : 'Defeat';
  };

  if (loading) {
    return <div className="loading animate__animated animate__fadeIn text-teal-500 text-lg">Loading matches...</div>;
  }

  if (error) {
    return <div className="error animate__animated animate__shakeX text-red-500 text-lg">{error}</div>;
  }

  return (
    <div className="container max-w-7xl mx-auto p-4 text-white">
      <h1 className="text-4xl text-center text-teal-500 my-6 animate__animated animate__fadeIn">Valorant Match History for {username}#{tag}</h1>
      {playerCard && (
        <div className="player-card flex items-center mb-6 animate__animated animate__fadeInLeft">
          <img src={playerCard} alt={`${username}#${tag}`} className="w-16 h-16 rounded-full mr-4 border-3 border-teal-500" />
          <div className="player-card-details">
            <p className="text-lg font-semibold">{username}#{tag}</p>
          </div>
        </div>
      )}
      <div className="match-grid grid grid-cols-1 gap-6">
        {matches.length > 0 ? (
          matches.map((match, index) => {
            const player = match.players.all_players.find(player => player.name === username && player.tag === tag);
            if (!player) {
              return null;
            }
            const matchClass = isWin(player.team, match) ? 'match win' : 'match lose';
            const matchStatus = getMatchResult(player.team, match);

            return (
              <div key={index} className={`${matchClass} p-6 bg-gray-800 rounded-lg shadow-lg relative animate__animated animate__zoomIn`}>
                <div className="flex justify-center items-center text-4xl font-bold mb-4">
                  <span className="text-teal-500">{match.teams.blue.rounds_won}</span>
                  <span className="mx-4 text-white">{matchStatus}</span>
                  <span className="text-red-500">{match.teams.red.rounds_won}</span>
                </div>
                <h2 className="text-2xl text-teal-500 mb-2">Match {index + 1}</h2>
                <p className="text-sm text-gray-400">Map: {match.metadata.map}</p>
                <p className="text-sm text-gray-400">Mode: {match.metadata.mode}</p>
                <p className="text-sm text-gray-400">Date: {match.metadata.game_start_patched}</p>
                <div className="teams flex justify-between mt-4">
                  <div className="team w-1/2 p-4 rounded-lg bg-blue-900">
                    <h3 className="text-2xl text-center text-teal-500 mb-2">Blue Team</h3>
                    {renderPlayers(match.players.all_players.filter(player => player.team === 'Blue'))}
                  </div>
                  <div className="team w-1/2 p-4 rounded-lg bg-red-900 ml-4">
                    <h3 className="text-2xl text-center text-red-500 mb-2">Red Team</h3>
                    {renderPlayers(match.players.all_players.filter(player => player.team === 'Red'))}
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <p className="text-gray-500">No matches found.</p>
        )}
      </div>
    </div>
  );
};

export default MatchHistory;
