import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles.css';

const Home = () => {
  const [username, setUsername] = useState('');
  const [tag, setTag] = useState('');
  const navigate = useNavigate();

  const handleSearch = () => {
    if (username && tag) {
      navigate(`/${username}/${tag}`);
    }
  };

  return (
    <div className="container max-w-7xl mx-auto p-4 text-white">
      <h1 className="text-4xl text-center text-teal-500 my-6 animate__animated animate__fadeIn">Welcome to Valorant Match History Viewer</h1>
      <div className="search-container flex flex-col items-center mb-6">
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="p-2 text-lg border-none rounded-md mb-2 bg-gray-800 text-white"
        />
        <input
          type="text"
          placeholder="Tag"
          value={tag}
          onChange={(e) => setTag(e.target.value)}
          className="p-2 text-lg border-none rounded-md mb-2 bg-gray-800 text-white"
        />
        <button onClick={handleSearch} className="p-2 text-lg bg-teal-500 text-white rounded-md transition-transform transform hover:scale-105">
          Search
        </button>
      </div>
    </div>
  );
};

export default Home;
